import React from 'react';
import axios from 'axios';
import parse from 'html-react-parser'

export default class ProjectFeed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {Tables: {}, InternalApproval: {}, TasksTable: {}}
        this.urlArgs = this.props.match.params.id.split("-")

    }

    componentDidMount() {
        // this.getProjects();
        this.getApprovals();
        this.getTasks();
    }

    async getApprovals() {
        // send a POST request
        const r = await axios({
            method: 'post',
            url: 'https://api.quickbase.com/v1/records/query',
            data: {
                "from": "brbr4qsh8",
                "select": [8, 6, 61],
                "where": `{51.EX.${this.urlArgs[1]}}`
            },
            headers: {
                'Authorization': 'QB-USER-TOKEN b47mvm_m6md_0_fg7ngqmnrdtxdfsnivhb2xg6h9',
                'QB-Realm-Hostname': 'bgprod.quickbase.com',
            }
        });

        const rows = {'Internal Approval': []}
        r.data['data'].map(function (element) {
            if (element[6]['value'] !== 'Proof Approval') {
                rows['Internal Approval'].push(element)
            }
            return element;
        });


        // build tables json
        const tables = {}
        tables['row'] = rows
        tables['fields'] = r.data['fields']
        this.setState({ InternalApproval: tables })
    }

    async getTasks() {
        // send a POST request
        const r = await axios({
            method: 'post',
            url: 'https://api.quickbase.com/v1/records/query',
            data: {
                "from": "brbbjyu8b",
                "select": [6, 168],
                "where": `{167.EX.${this.urlArgs[1]}}AND{6.XEX."Artwork Creation"}`
            },
            headers: {
                'Authorization': 'QB-USER-TOKEN b47mvm_m6md_0_fg7ngqmnrdtxdfsnivhb2xg6h9',
                'QB-Realm-Hostname': 'bgprod.quickbase.com',
            }
        });

        const rows = {'Tasks Table': []}
        r.data['data'].map(function (element) {
            rows['Tasks Table'].push(element)
            return element;
        });


        // build tables json
        const tables = {}
        tables['row'] = rows
        tables['fields'] = r.data['fields']
        this.setState({TasksTable: tables})
    }

    async getProjects() {
        // send a POST request
        const r = await axios({
            method: 'post',
            url: 'https://api.quickbase.com/v1/records/query',
            data: {
                "from": "brbbjyu8b",
                "select": [6, 7, 11, 13],
                "where": "{7.EX.137}"
            },
            headers: {
                'Authorization': 'QB-USER-TOKEN b47mvm_m6md_0_fg7ngqmnrdtxdfsnivhb2xg6h9',
                'QB-Realm-Hostname': 'bgprod.quickbase.com',
            }
        });

        const rows = {}
        r.data['data'].map(function (element) {
            const assetType = element[13]['value'];
            // check if rows json has key of asset type
            if (rows.hasOwnProperty(assetType)) {
                rows[assetType].push(element)
            } else {
                rows[assetType] = []
                rows[assetType].push(element)
            }
            return element;
        });

        // build tables json
        const tables = {}
        tables['row'] = rows
        tables['fields'] = r.data['fields']
        this.setState({ Tables: tables })
    }

    buildTables() {
        const approvalTables = this.state.InternalApproval;
        const tasksTable = this.state.TasksTable;
        let tables = [];
        // check if data has loaded

        for (const k in tasksTable['row']) {
            const v = tasksTable['row'][k]
            tables.push(
                <div>
                    <div className='w-100 bg-light p-2 text-muted'></div>
                    <table className='table table-striped mb-0 mt-0'
                           style={{backgroundColor: 'white', width: '100%'}}>
                        <thead className='bg-dark text-light'>
                        <tr>
                            <th style={{width: '50%'}}>Task </th>
                            <th style={{width: '50%'}}>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {v.map((value) => {
                            const status = value[168]['value'].replace('padding: 8px 20px', 'padding: 0')
                            return (
                                <tr>
                                    <td>{value[6]['value'].toString()}</td>
                                    <td className='status-container'>{parse(status)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            )
        }

        for (const k in approvalTables['row']) {
            const v = approvalTables['row'][k]
            tables.push(
                <div>
                    <table className='table table-striped mb-0 mt-0'
                           style={{backgroundColor: 'white', width: '100%'}}>
                        <thead className='bg-dark text-light'>
                        <tr>
                            <th style={{width: '50%'}}>Approval</th>
                            <th style={{width: '50%'}}>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {v.map((value) => {
                            const status = value[61]['value'].replace('padding: 8px 20px', 'padding: 0')
                            return (
                                <tr>
                                    <td colSpan="1">{value[6]['value'].toString()}</td>
                                    <td colSpan="1" className='status-container'>{parse(status)}</td>
                                </tr>
                            )
                        })}
                        </tbody>
                    </table>
                </div>
            )
        }

        return (
            tables
        )
    }

    render() {
        return (
            <div className='card shadow-sm mt-3 w-100'>
                <div className='card-header text-muted nb font-weight-bold'>

                </div>
                {this.buildTables()}
            </div>
        )
    }
}

