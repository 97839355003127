import React from 'react';



import PDFViewer from './PDFViewer';
import Scanning from './Scanning';
import ProjectFeed from './ProjectFeed';


import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";


import './App.css';
import HeaderTitle from "./HeaderTitle";

// Matthew test commit


const App = () => {



    return (
        <Router>
            <Switch>
                <div className='h-100' style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column' }}>
                    <nav className="navbar navbar-dark bg-dark mb-3 p-3">
                        <a className="navbar-brand" href="#">
                            <img src="https://www.bgprod.com/bg-master/uploads/BG-logo-80.png" height="30"
                                className="d-inline-block align-top" alt="" />
                            <span style={{ paddingLeft: '1rem', fontWeight: 600 }}><Route path="/:id" component={HeaderTitle} /></span>
                        </a>
                    </nav>
                    <div className='container-fluid h-100 mb-3'>
                        <div className='row h-100 no-gutters'>

                            <div className='col-lg-9' style={{paddingRight: '0'}}>

                                <div className='card h-100 shadow-sm'>
                                    <div className='card-header text-muted nb font-weight-bold'>Annotation</div>
                                    <div className='card-body' style={{ padding: '0' }}>
                                        <Route path="/:id" component={PDFViewer} />
                                    </div>
                                </div>
                            </div>

                            <div className='col-lg-3'>

                                <div className="approvals h-100" style={{display: 'flex', flexDirection: 'column'}}>
                                    <div id='barcode-stuff-placeholder' style={{flexBasis: '10%'}}>
                                        <Route path="/:id" component={Scanning}/>
                                    </div>
                                    <div style={{display: 'flex'}} className='w-100 h-100'>
                                        <Route path="/:id" component={ProjectFeed}/>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Switch>
        </Router>


    );
};


export default App;