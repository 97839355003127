import React from "react";


export default class HeaderTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = { title: "..." }
        this.urlArgs = this.props.match.params.id.split("-")
        this.rid = this.urlArgs[1]
    }

    componentDidMount() {

        const BD_HEADERS = {
            'QB-Realm-Hostname': 'bgprod.quickbase.com',
            'Authorization': 'QB-USER-TOKEN b47mvm_m6md_0_fg7ngqmnrdtxdfsnivhb2xg6h9',
            'Content-Type': 'application/json'
        }

        const body = {
            "from": "brbgxb4nr",
            "select": [
                42
            ],
            "where": `{3.EX.${this.rid}}`
        }

        fetch("https://api.quickbase.com/v1/records/query", {
            method: "POST",
            headers: BD_HEADERS,
            body: JSON.stringify(body)
        })
            .then(res => res.json())
            .then(
                (result) => {
                    this.setState({
                        title: result.data[0][42].value,
                    });
                },
                (error) => {
                    this.setState({
                        isLoaded: true,
                        error
                    });
                }
            )
    }

    render() {
        return (
            <span>
                {this.state.title}
            </span>
        )
    }
}