import React from "react";
import axios from "axios";


export default class BarcodeCheck extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            projectBarcode: null,
            match: false
        }
    }

    componentDidMount() {
        this.checkBarcode().then(r => this.setState({projectBarcode: r.data.data[0][37].value}))
    }


    async checkBarcode() {
        // send a POST request
        const rid = this.props.rid
        return axios({
            method: 'post',
            url: 'https://api.quickbase.com/v1/records/query',
            data: {
                "from": "brbgxb4nr",
                "select": [37],
                "where": `{3.EX.${rid}}`
            },
            headers: {
                'Authorization': 'QB-USER-TOKEN b47mvm_m6md_0_fg7ngqmnrdtxdfsnivhb2xg6h9',
                'QB-Realm-Hostname': 'bgprod.quickbase.com',
            }
        });

    }

    render() {
        let match = false
        if (this.state.projectBarcode === this.props.input) {
            match = true
        }
        return (
            <div>
                { this.props.input === null && <div className='card m-2'><div className='card-body'><h4 className='text-warning'>No barcode detected</h4></div></div> }
                { match && <div className='card m-2'><div className='card-body'><h4 className='text-success'>Barcode matches GTIN!</h4></div></div> }
                { !match && <div className='card m-2'><div className='card-body'><h4 className='text-danger'>Barcode does not match GTIN!</h4></div></div> }
            </div>
        )
    }
}