import React, { useState } from 'react';
import Quagga from 'quagga';
import Modal from 'react-modal';
import ReactCrop from 'react-image-crop';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { pdfjs } from 'react-pdf';
import 'react-image-crop/dist/ReactCrop.css';
import BarcodeCheck from "./BarcodeCheck";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
var Barcode = require('react-barcode');


const BD_FILE_URL = 'https://api.quickbase.com/v1/files/brbgxb4nr/'
const BG_HEADERS = {
    'QB-Realm-Hostname': 'bgprod.quickbase.com',
    'Authorization': 'QB-USER-TOKEN b47mvm_m6md_0_fg7ngqmnrdtxdfsnivhb2xg6h9',
    'Content-Type': 'application/json'
}


export default class Scanning extends React.Component {
    constructor(props) {
        super(props);
        this.urlArgs = this.props.match.params.id.split("-")
        this.state = {
            CurrentTab: 'Barcode',
            BarcodeText: '766041120946',
            barcodeResult: null,
            projectBarcode: null,
            screenCapture: '',
            showModal: false,
            selectedPage: 1,
            pdfImg: '',
            crop: {
                unit: '%',
            },
            croppedImage: '',
            pdfBlob: null,
        }
        this.myRef = React.createRef()
        this.pageNumber = 0
        this.handleOpenModal = this.handleOpenModal.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.onRenderSuccess = this.onRenderSuccess.bind(this);
        this.handleBarcodeSubmit = this.handleBarcodeSubmit.bind(this);


    }



    async getPDFBlob(url, rid) {
        const response = await fetch(url + rid + '/9/0', {
            method: 'GET',
            headers: BG_HEADERS
        })

        if (response.ok) {
            const reader = response.body.getReader();
            const decoder = new TextDecoder()

            let base64String = ''
            while (true) {
                const { done, value } = await reader.read()
                if (done) {
                    break
                }
                base64String += decoder.decode(value)
            }

            // decode base64 string, remove space for IE compatibility
            let binary = atob(base64String);
            let len = binary.length;
            let buffer = new ArrayBuffer(len);
            let view = new Uint8Array(buffer);
            for (let i = 0; i < len; i++) {
                view[i] = binary.charCodeAt(i);
            }

            // create the blob object with content-type "application/pdf"
            this.setState({ pdfBlob: new Blob([view], { type: "application/pdf" }) })
        }
    }

    componentDidMount() {
        this.getPDFBlob(BD_FILE_URL, this.urlArgs[1])
    }


    // If you setState the crop in here you should return false.
    onImageLoaded = image => {
        this.imageRef = image;
    };

    onCropComplete = crop => {
        this.makeClientCrop(crop).then(r => this.handleBarcodeScan());
    };

    onCropChange = (crop, percentCrop) => {
        // You could also use percentCrop:
        // this.setState({ crop: percentCrop });
        this.setState({ crop });
    };

    async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
            const croppedImageUrl = await this.getCroppedImg(
                this.imageRef,
                crop,
                'newFile.jpeg'
            );
            this.setState({ croppedImageUrl });
            this.setState({ croppedImage: croppedImageUrl });
        }
    }

    getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error('Canvas is empty');
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(this.fileUrl);
                this.fileUrl = window.URL.createObjectURL(blob);
                resolve(this.fileUrl);
            }, 'image/jpeg');
        });
    }


    onRenderSuccess() {
        const importPDFCanvas: HTMLCanvasElement = document.querySelector('.import-pdf-page canvas');
        const pdfAsImageSrc = importPDFCanvas.toDataURL();
        this.setState({ pdfImg: pdfAsImageSrc })
    };


    handleOpenModal() {
        this.setState({ showModal: true });
        // var image = new Image();
        // image.id = "pic";
        // image.src = document.getElementById('').toDataURL();
        // document.getElementById('image_for_crop').appendChild(image);
    }

    handleCloseModal() {
        this.setState({ showModal: false });
    }

    handleBarcodeSubmit() {
        this.setState({ projectBarcode: this.state.barcodeResult });
        this.handleCloseModal();
    }

    handleBarcodeScan() {
        let res = 'None'
        const self = this;
        Quagga.decodeSingle({

            decoder: {
                readers: ["code_39_reader", "upc_reader"]
            },
            locate: false,
            src: this.state.croppedImage
        }, function (result) {
            if (result.codeResult) {
                res = result.codeResult.code
                let fmt = result.codeResult.format
                self.setState({ barcodeResult: res })
                self.setState({ barcodeFormat: fmt })
                console.log(fmt)
            } else {
                res = 'None'
                self.setState({ barcodeResult: null })
            }

        }

        );
    };

    handleSave = () => {
        const screenCaptureSource = this.state.screenCapture;
        const downloadLink = document.createElement('a');
        const fileName = 'react-screen-capture.png';

        downloadLink.href = screenCaptureSource;
        downloadLink.download = fileName;
        downloadLink.click();
    };

    createBarcode() {
        return (
            <div>Barcode Create</div>
        )
    }

    handleBarcodeChange = event => {
        this.setState({ BarcodeText: event.target.value })
    }

    handleBarcodeDownload = event => {
        const c = document.getElementById("barcode-container").childNodes;
        event.target.href = c[0].toDataURL();
        event.target.download = this.state.BarcodeText + '.png';
    }

    handlePageSelect = event => {
        console.log(event.target.value)
        this.setState({ selectedPage: parseInt(event.target.value) })
    }


    renderModalContent() {
        const { crop, croppedImageUrl, src } = this.state;
        return (
            <div>
                <div style={{ display: 'none' }}>
                    <Document file={this.state.pdfBlob}>
                        <Page
                            pageNumber={this.state.selectedPage}
                            width={1000}
                            height={1000}
                            scale={2.0}
                            className='import-pdf-page'
                            onRenderSuccess={this.onRenderSuccess}
                        />
                    </Document>

                </div>

                <div style={{ width: '100%', height: '100%', display: 'flex' }}>
                    <div style={{ flexBasis: '85%', overflowY: 'scroll', maxHeight: '80vh' }}
                        className='shadow m-2 border border-secondary'>

                        <ReactCrop
                            src={this.state.pdfImg}
                            crop={crop}
                            zoom={1}
                            ruleOfThirds
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            onChange={this.onCropChange}
                            style={{ border: '1px solid gray' }}
                        />
                    </div>

                    <div style={{ flexBasis: '15%' }}>
                        <div style={{ display: 'flex', flexDirection: "column" }}>

                            <h6 className='text-muted m-2 mt-5'>
                                Instructions:
                            </h6>
                            <p className='text-muted m-2'>
                                Select the barcode by clicking and
                                dragging over the area of the barcode.
                                When the barcode has been correctly scanned,
                                click confirm.
                            </p>

                            <label className='m-2'>Select Page</label>
                            <select className='form-control m-2' onChange={this.handlePageSelect}>
                                <option value='1'>1</option>
                                <option value='2'>2</option>
                            </select>
                            <button className='btn btn-success m-2 w-100' onClick={this.handleBarcodeSubmit}
                                disabled={!this.state.barcodeResult}>

                                Confirm
                            </button>
                            <button className='btn btn-secondary m-2 w-100' onClick={this.handleCloseModal}>
                                Cancel
                            </button>
                        </div>

                        {croppedImageUrl && (<div>
                            <div className='card m-2'>
                                <div
                                    className='card-body d-flex flex-column justify-content-center align-items-center'>
                                    <img crossOrigin="anonymous"
                                        alt="Crop" style={{ maxWidth: '100%' }}
                                        src={croppedImageUrl} />
                                    <div style={{ textAlign: "center", fontSize: "x-large" }}
                                        className={`w-100 border ${this.state.barcodeResult ? "border-success" : "border-danger"}`}>
                                        {this.state.barcodeResult}
                                    </div>
                                    <div>
                                        {!this.state.barcodeResult && (<div className='m-2 text-muted'>
                                            Tip: try to add some space around the barcode when selecting.
                                        </div>)}
                                        {this.state.barcodeResult && (<div className='m-2 text-muted'>
                                            Successfully detected!
                                        </div>)}
                                    </div>
                                </div>

                                <div>
                                    {this.state.barcodeFormat === "upc_a" && <BarcodeCheck input={this.state.barcodeResult} rid={this.urlArgs[1]} />}
                                </div>

                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    updateContent() {

        // let projectBarcode = <div>Barcode not set. Use "Select Barcode" to set the current barcode.</div>
        // let projectBarcodeIcon = <i className="fas fa-eye-slash" style={{fontSize: '5rem'}}></i>
        // if (this.state.projectBarcode) {
        //     projectBarcode = <div className='text-light bg-dark p-2'>{this.state.projectBarcode}</div>
        //     projectBarcodeIcon = <i className="fas fa-barcode" style={{fontSize: '5rem'}}></i>
        // }

        const barcodeContent = (
            <div>
                <div style={{ display: "flex", width: "100%" }}>
                    <input type='text' onChange={this.handleBarcodeChange} placeholder='Convert to barcode...'
                        style={{ flexBasis: '75%', marginRight: '1rem' }} />
                    {/*<button className'btn btn-outline-primary' onClick={this.handleBarcodeDownload}>Save as Image</button>*/}
                    <a
                        id="download"
                        download="barcode.png"
                        onClick={this.handleBarcodeDownload} className='btn btn-outline-primary'
                        style={{ flexBasis: '25%' }}>
                        Download
                    </a>
                </div>
                <div id='barcode-container'
                    style={{ display: "flex", alignContent: "center", justifyContent: "center", overflowX: "scroll" }}>
                    <Barcode value={this.state.BarcodeText} renderer='canvas' id='barcode-canvas' />
                </div>
            </div>
        )

        const scannerContent = (

            <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                <div className='p-2'>

                    <button
                        className='btn btn-outline-primary w-100'
                        onClick={this.handleOpenModal}>
                        <i className="fas fa-crop" /> Select Barcode
                    </button>
                </div>
                {/*<div style={{flexBasis: "75%"}} className='p-2'>*/}
                {/*    <div className='d-flex'>*/}
                {/*        <div style={{flexBasis: '25%'}} className='p-2 text-muted'>*/}
                {/*            {projectBarcodeIcon}*/}
                {/*        </div>*/}
                {/*        <div style={{flexBasis: '75%', fontSize: 'large', fontWeight: 'bolder'}}*/}
                {/*             className='p-2 text-muted justify-content-center align-items-center d-flex'>*/}
                {/*            {projectBarcode}*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}

            </div>
        )

        let content = (<div>Loading...</div>)
        if (this.state.CurrentTab === 'Scanner') {
            content = barcodeContent
        } else {
            content = scannerContent
        }

        return (
            content
        )
    }

    createScanner() {
        return (
            <div>Scanner Create</div>
        )
    }

    render() {
        return (
            <div className='card' style={{ height: '100%' }}>
                <div className='card-header text-muted nb'>
                    <ul className="nav nav-tabs card-header-tabs" style={{ color: "gray" }}>
                        {/*<li className="nav-item">*/}
                        {/*    <button*/}
                        {/*        className={`nav-link text-reset ${this.state.CurrentTab === 'Barcode' ? "active" : ""}`}*/}
                        {/*        onClick={() => this.setState({CurrentTab: 'Barcode'})}>Create Barcode*/}
                        {/*    </button>*/}
                        {/*</li>*/}
                        <li className="nav-item">
                            <button
                                className={`nav-link text-reset ${this.state.CurrentTab === 'Scanner' ? "active" : ""}`}
                                onClick={() => this.setState({ CurrentTab: 'Scanner' })}>Scan Barcode
                            </button>
                        </li>
                    </ul>
                </div>
                <div className='card-body'>
                    {this.updateContent()}
                </div>
                <Modal
                    isOpen={this.state.showModal}
                    contentLabel="Minimal Modal Example"
                >
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row-reverse' }}>
                        <button onClick={this.handleCloseModal} className='btn btn-outline-danger'>
                            <i className="fas fa-window-close"></i>
                        </button>
                    </div>
                    <div>
                        {this.renderModalContent()}
                    </div>
                </Modal>
            </div>
        )
    }
}